import translationsWebsite from "./translations_website";

const translations = {

    // AUTH
    "ulogujte se": {"sr": "Ulogujte se", "en": "Login"},
    "registrujte se": {"sr": "Registrujte se", "en": "Register"},
    "registrujte nalog": {"sr": "Registrujte nalog", "en": "Register account"},
    "zapamti me": {"sr": "Zapamti me", "en": "Remember me"},
    "unesite vas email": {"sr": "Unesite vaš email", "en": "Enter your email"},
    "posaljite email": {"sr": "Pošaljite email", "en": "Send email"},
    "lozinka": {"sr": "Lozinka", "en": "Password"},
    "nova lozinka": {"sr": "Nova Lozinka", "en": "New Password"},
    "resetujte lozinku": {"sr": "Resetujte lozinku", "en": "Reset password"},
    "zaboravili ste lozinku": {"sr": "Zaboravili ste lozinku?", "en": "Forgot your password?"},
    "izlogujte se": {"sr": "Izlogujte se", "en": "Logout"},
    "korisnicko ime": {"sr": "Korisničko ime", "en": "Username"},
    "ime": {"sr": "Ime", "en": "Name"},
    "naziv": {"sr": "Naziv", "en": "Name"},
    "prezime": {"sr": "Prezime", "en": "Lastname"},
    "ulica": {"sr": "Ulica", "en": "Street"},
    "mesto": {"sr": "Mesto", "en": "Place"},
    "posaljite": {"sr": "Pošaljite", "en": "Send"},
    "postanski broj": {"sr": "Poštanski broj", "en": "Zip Code"},
    'ne postoji stranica': {sr: 'Ne postoji stranica.', en: "Page doesn't exists."},

    // COOKIES
    "kolacici nisu odobreni za ovu uslugu": {
        sr: 'Kolačići nisu odobreni za ovu uslugu.',
        en: "Cookies not approved for this service."
    },
    "odobrite": {sr: "Odobrite", en: "Accept"},

    // validator
    "polje je obavezno": {"sr": "Polje je obavezno.", "en": "Field is required."},
    "polje nije validan email": {"sr": "Polje nije validan email.", "en": "Field is not valid email."},
    "polje mora biti min": {"sr": "Polje mora biti minimum: ", "en": "Field must be minimum: "},
    "polje mora biti max": {"sr": "Polje mora biti maksimalno: ", "en": "Field must be maximum: "},
    "karaktera": {"sr": " karaktera.", "en": " characters."},

    // GENERAL
    "idite na sajt": {"sr": "Idite na sajt", "en": "Go to website"},
    "sacuvajte": {"sr": "Sačuvajte", "en": "Save"},
    "currency": {"sr": "RSD", "en": "EUR"},
    "sacuvano": {"sr": "Sačuvano.", "en": "Saved."},
    "obrisite": {"sr": "Obrišite", "en": "Delete"},
    "obrisano": {"sr": "Obrisano.", "en": "Deleted."},
    "dodajte": {"sr": "Dodajte", "en": "Add"},
    "pozicija naslova": {"sr": "Pozicija naslova", "en": "Title position"},
    "pod": {"sr": "pod", "en": "sub"},
    "detaljnije": {"sr": "Detaljnije", "en": "More"},
    "odaberi": {"sr": "Odaberi", "en": "Select"},
    "zatvorite": {"sr": "Zatvorite", "en": "Close"},
    "naslovna": {"sr": "Naslovna", "en": "Home"},
    "nije postavljeno": {"sr": "Nije postavljeno.", "en": "Not set."},
    "zelite da obrisete": {"sr": "Želite da obrišete?", "en": "Do you want to delete?"},

    "kontaktirajte nas": {"sr": "Kontaktirajte nas", "en": "Contact us"},
    "adresa": {"sr": "Adresa", "en": "Address"},
    "ime i prezime": {"sr": "Ime i prezime", "en": "Full name"},
    "predmet": {"sr": "Predmet", "en": "Subject"},
    "broj telefona": {"sr": "Broj telefona", "en": "Phone number"},
    "poruka": {"sr": "Poruka", "en": "Message"},
    // NESTABLE
    "prevucite da promenite raspored": {"sr": "Prevucite da promenite raspored.", "en": "Drag to change order."},
    "premestite": {"sr": "Premestite", "en": "Drag to move"},
    "raspored sacuvan": {"sr": "Raspored sačuvan.", "en": "Order saved."},
    "otvorite sve": {"sr": "Otvorite sve", "en": "Open all"},
    "zatvorite sve": {"sr": "Zatvorite sve", "en": "Close all"},
    "predmet nije aktiviran i nece se prikazivati": {
        "sr": "Predmet nije aktiviran i neće se prikazivati.",
        "en": "Item is not activated and will not be displayed."
    },
    "aktivirano": {"sr": "Aktivirano", "en": "Activated"},
    "deaktivirano": {"sr": "Deaktivirano", "en": "Deactivated"},
    "doslo je do greske na stranici": {"sr": "Došlo je do greske na stranici.", "en": "A page error has occurred."},

    // PAGES
    "stranica": {"sr": "Stranica", "en": "Page"},
    "stranice": {"sr": "Stranice", "en": "Pages"},
    "dodajte stranu": {"sr": "Dodajte stranu", "en": "Add page"},
    // ADMIN
    "administrator": {"sr": "Administrator", "en": "Administrator"},
    "dodajte administratora": {"sr": "Dodajte administratora", "en": "Add administrator"},
    "broj pogresnih logovanja": {"sr": "Broj pogrešnih logovanja.", "en": "Wrong attempts to login"},
    // USERS
    "korisnik": {"sr": "Korisnik", "en": "User"},
    "banujte": {"sr": "Banujte", "en": "Ban"},
    "nalog": {"sr": "Nalog", "en": "Account"},
    "uklonite ban": {"sr": "Uklonite ban", "en": "Unban"},
    "registrovan putem googla": {"sr": "Registrovan putem googla.", "en": "Registered by google."},
    "korisnik je banovan": {"sr": "Korisnik je banovan.", "en": "User is banned."},
    "zelite da banujete korisnika": {"sr": "Želite da banujete korisnika?", "en": "Want to ban user?"},
    "zelite da uklonite ban": {"sr": "Želite da uklonite ban?", "en": "Want to remove ban?"},
    // paginator
    "od": {"sr": "od", "en": "from"},
    "prazna pretraga": {"sr": "Prazna pretraga.", "en": "Empty search."},
    "prazno": {"sr": "Prazno.", "en": "Empty."},
    "sve": {"sr": "Sve", "en": "All"},
    "strana": {"sr": "strana", "en": "page"},
    "banovani": {"sr": "banovani", "en": "banned"},
    "pretraga": {"sr": "pretraga", "en": "search"},
    "status": {"sr": "status", "en": "status"},
    "stanje": {"sr": "stanje", "en": "state"},
    // STorage
    "folderi": {"sr": "Folderi", "en": "Folders"},
    "folder": {"sr": "folder", "en": "folder"},
    "dodajte folder": {"sr": "Dodajte folder", "en": "Add folder"},
    "fajlovi": {"sr": "Fajlovi", "en": "Files"},
    "uploadovano": {"sr": "Uploadovano.", "en": "Uploaded."},
    "prebacite fajlove u": {"sr": "Prebacite fajlove u", "en": "Trasfer files to"},
    "dodajte fajlove": {"sr": "Dodajte fajlove", "en": "Add files"},
    "max velicina fajla za upload": {"sr": "MAX veličina fajla za upload", "en": "MAX upload file size"},
    "selektujte sve": {"sr": "Selektujte sve", "en": "Select all"},
    "obrisite sve": {"sr": "Obrišite sve", "en": "Delete all"},
    "zelite da prebacite fajlove": {"sr": "Želite da prebacite fajlove?", "en": "Want to transfer files?"},
    "prebacite u": {"sr": "Prebacite u", "en": "Transfer to"},
    "kliknite na fajl da promenite": {"sr": "Kliknite na fajl da promenite.", "en": "Click on file to change."},
    "kliknite na sliku da promenite": {
        "sr": "Kliknite na sliku da promenite",
        "en": "Click on the image to change"
    },
    "odaberite selektovano": {"sr": "Odaberite selektovano", "en": "Get selected"},
    // Proizvodi
    "proizvod": {"sr": "proizvod", "en": "product"},
    "opis": {"sr": "Opis", "en": "Description"},
    "proizvodi": {"sr": "Proizvodi", "en": "Products"},
    "proizvodi u carouselu": {"sr": "Proizvodi u carousel-u", "en": "Products in carousel"},
    "kategorije u carouselu": {"sr": "Kategorije u carousel-u", "en": "Categories in carousel"},
    "proizvoda": {"sr": "proizvoda", "en": "products"},
    "proizvod je deaktiviran": {"sr": "Proizvod je deaktiviran.", "en": "Product is deactivated."},
    "na stanju": {"sr": "Na stanju", "en": "On state"},
    "nema na stanju": {"sr": "Nema na stanju", "en": "No state"},
    "dodajte proizvod": {"sr": "Dodajte proizvod", "en": "Add new product"},
    "prebacite na top": {"sr": "Prebacite na top", "en": "Move to top"},
    "sortiraj": {"sr": "Sortiraj", "en": "Sort"},

    // CATEGORIES
    "kategoriju": {"sr": "kategoriju", "en": "category"},
    "kategorija": {"sr": "kategorija", "en": "category"},
    "kategorije": {"sr": "Kategorije", "en": "categories"},
    "kategorije params": {"sr": "k", "en": "c"},
    // Filter
    "filter": {"sr": "Filter", "en": "Filter"},
    "filteri": {"sr": "Filteri", "en": "Filters"},
    "filteri params": {"sr": "f", "en": "f"},
    // DESIGN
    "blokovi": {"sr": "Blokovi", "en": "Blocks"},
    "naslov": {"sr": "Naslov", "en": "Title"},
    "podnaslov": {"sr": "Podnaslov", "en": "Subtitle"},
    "dodajte blok": {"sr": "Dodajte blok", "en": "Add block"},
    "blok nije poceo": {"sr": "Blok nije počeo", "en": "Block not started"},
    "blok je istekao": {"sr": "Blok je istekao", "en": "Block is expired"},
    "blok pocinje": {"sr": "Blok počinje", "en": "Block starts"},
    "blok se zavrsava": {"sr": "Blok se završava", "en": "Block ends"},
    "naslov u boji": {"sr": "Naslov u boji", "en": "Colored title"},
    "sirina celog ekrana": {"sr": "Širina celog ekrana", "en": "Full screen width"},
    "broj predmeta u jednom redu": {"sr": "Broj predmeta u jednom redu", "en": "Items in one row"},
    "naslov, text i slika": {"sr": "Naslov, text i slika", "en": "Card block"},
    "ime za link dugme": {"sr": "Ime za link dugme", "en": "Link button label"},
    "link": {"sr": "Link", "en": "Link"},

    // banners
    "baner": {"sr": "baner", "en": "banner"},
    "baneri": {"sr": "Baneri", "en": "Banners"},
    "slika": {"sr": "Slika", "en": "Image"},
    "slika za mobilni": {"sr": "Slika za mobilni", "en": "Mobile image"},
    "prikazuj na mobilnom": {"sr": "Prikazuj na mobilnom", "en": "Show on mobile."},
    "prikazuj sliku kao popup": {"sr": "Prikazuj sliku kao POPUP", "en": "Prikazuj sliku kao POPUP"},
    "otvaraj u novom prozoru": {"sr": "Klikom otvaraj u novom prozoru.", "en": "On click open in new window."},
    "opis slike": {"sr": "Opis (alt) slike.", "en": "Image description (alt)."},
    "link slike": {"sr": "Link slike.", "en": "Image link."},
    "visina banera": {"sr": "Visina bannera", "en": "Banner height"},
    "centriraj banere": {"sr": "Centriraj banere", "en": "Banner position"},
    "gap": {"sr": "gap (razmak)", "en": "gap"},

    // Text editor
    "text editor": {"sr": "Text editor", "en": "Text editor"},

    // SETTINGs
    "settings email info": {
        "sr": "više emailova odvojite zarezom: email1@gmail.com,email2@gmail.com...",
        "en": "separate multiple emails with a comma: email1@gmail.com,email2@gmail.com..."
    },
    "settings tel info": {
        "sr": "više telefona odvojite zarezom: 064 555 555,064 333 333...",
        "en": "separate multiple phones with a comma: 064 555 555,064 333 333..."
    },
    // BLOG
    "blog": {"sr": "vest", "en": "blog"},
    "kreirano": {"sr": "Kreirano", "en": "Created"},
    "poslednje vesti": {"sr": "Poslednje vesti", "en": "Latest news"},

    // COOKIES
    "cookies title": {"sr":"Ovaj veb sajt koristi kolačiće", "en":"This website uses cookies"},
    "cookies desc": {
        "sr": "Koristimo kolačiće za personalizaciju sadržaja, pružanje funkcija društvenih medija i analiziranje saobraćaja. Ako nastavite da koristite naše veb-stranice, saglasni ste sa korišćenjem naših kolačića.",
        "en": "We use cookies to personalize content, to provide functions social media and traffic analysis. If you continue to use our website, you agree to the use of our cookies."
    },
    "prihvatam": {"sr": "PRIHVATAM", "en": "ACCEPT"},


    // SEO
    "kreiraj novi sitemap svakih": {"sr": "Kreiraj novi sitemap svakih", "en": "Create new sitemap every"},
    "dana": {"sr": "dana", "en": "days"},
    // KONTAKT
    "podaci o firmi": {"sr": "Podaci o firmi", "en": "Company information"},
    "preuzmite": {"sr": "Preuzmite", "en": "Download"},
    "telefoni": {"sr": "Telefoni", "en": "Phones"},

    // LISTA ZELJA
    "lista zelja": {"sr": "Lista želja", "en": "Wishlist"},
    "lista zelja je prazna": {"sr": "Lista želja je prazna.", "en": "Wishlist is empty."},

}
const combined = {...translations, ...translationsWebsite};
export default combined;