import {useEffect} from "react";
import context from "../../_app/context";
import {makeVar} from "@apollo/client";
import {useGetSettingsAdmin} from "../../../../../client/src/modules/_graphql/GetSettingsAdmin";
import {useGetSettings} from "../../../../../client/src/modules/_graphql/GetSettings";

//@@@ type=register

const settingsVar = makeVar({});

function LoadSettingsRegister()   {
    context.debugger("LoadSettingsRegister");
    context.settings = settingsVar;

    const loader = context.admin() ? useGetSettingsAdmin : useGetSettings;
    const {data, error} = loader();

    useEffect(() => {
        if(!data || data.length === 0)  return;

        const holder = {};
        for(let i=0; i<data.length; i++)    {
            holder[data[i].field] = data[i];
        }

        context.settings(holder);
    }, [data]);

    if(error)   return error;

    return null
}
export default LoadSettingsRegister;