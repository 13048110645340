import {gql} from "@apollo/client";
import {StorageFragment} from "./Storage";

export const BlogFragment = gql`
	fragment BlogFragment on Blog {
		id
		code
		title
		description
		created_at
	}
`

export const BlogFragmentAdmin = gql`
	fragment BlogFragmentAdmin on Blog {
		id
		code
		title
		titles
		description
		descriptions
		created_at
	}
`

export const GetBlog = gql`
	${BlogFragment}
	${StorageFragment}
	query ($id: ID!, ) {
		GetBlog (id: $id, ) {
			...BlogFragment
			image			{
				...StorageFragment
			}
		}
	}
`

export const GetBlogAdmin = gql`
	${BlogFragmentAdmin}
	${StorageFragment}
	query ($id: ID!, ) {
		GetBlogAdmin (id: $id, ) {
			...BlogFragmentAdmin
			image			{
				...StorageFragment
			}
		}
	}
`

export const GetBlogs = gql`
	${BlogFragment}
	${StorageFragment}
	query ($first: Int, $page: Int, $search: String, ) {
		GetBlogs (first: $first, page: $page, search: $search, ) {
			data {
			...BlogFragment
			image			{
				...StorageFragment
			}
			}
			paginatorInfo {
				currentPage
				lastPage
				count
				total
			}
		}
	}
`

export const GetBlogsAdmin = gql`
	${BlogFragmentAdmin}
	${StorageFragment}
	query ($first: Int, $page: Int, $search: String, ) {
		GetBlogsAdmin (first: $first, page: $page, search: $search, ) {
			data {
			...BlogFragmentAdmin
			image			{
				...StorageFragment
			}
			design_blocks_count

			}
			paginatorInfo {
				currentPage
				lastPage
				count
				total
			}
		}
	}
`

export const CreateBlog = gql`
	${BlogFragmentAdmin}
	mutation ($title: String!, $code: String, ) {
		CreateBlog (title: $title, code: $code, ) {
			...BlogFragmentAdmin
		}
	}
`

export const UpdateBlog = gql`
	${BlogFragmentAdmin}
	mutation ($id: ID!, $image_id: String, $title: String, $description: String, $created_at: String, ) {
		UpdateBlog (id: $id, image_id: $image_id, title: $title, description: $description, created_at: $created_at, ) {
			...BlogFragmentAdmin
		}
	}
`

export const DeleteBlog = gql`
	${BlogFragmentAdmin}
	mutation ($id: ID!, ) {
		DeleteBlog (id: $id, ) {
			...BlogFragmentAdmin
		}
	}
`

