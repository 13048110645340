import RouterProgressBar from "./RouterProgressBar";
import ScrollToTop from "./ScrollToTop";
import RouterSeoPageChange from "../seo/widgets/RouterSeoPageChange";

//@@@ type=widget
//@@@ import=true

function RouterChangedActions() {

    return <>
        <RouterSeoPageChange />
        <RouterProgressBar />
        <ScrollToTop />
    </>
}
export default RouterChangedActions;