import {gql} from "@apollo/client";
import {StorageFragment} from "./Storage";

export const SettingFragment = gql`
	fragment SettingFragment on Setting {
		id
		field
		value
	}
`

export const SettingAdminFragment = gql`
	fragment SettingAdminFragment on Setting {
		id
		hidden
		field
		description
		value
		values
		folder
		element
		lang
	}
`

export const GetSettings = gql`
	${SettingFragment}
	${StorageFragment}
	query ($active: Boolean, $protected: Boolean, ) {
		GetSettings (active: $active, protected: $protected, ) {
			...SettingFragment
			image			{
				...StorageFragment
			}
		}
	}
`

export const GetSettingsAdmin = gql`
	${SettingAdminFragment}
	${StorageFragment}
	query ($active: Boolean, ) {
		GetSettingsAdmin (active: $active, ) {
			...SettingAdminFragment
			image			{
				...StorageFragment
			}
		}
	}
`

export const UpdateSetting = gql`
	${SettingAdminFragment}
	mutation ($id: ID!, $value: String, $image_id: ID, ) {
		UpdateSetting (id: $id, value: $value, image_id: $image_id, ) {
			...SettingAdminFragment
		}
	}
`

