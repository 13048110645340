import {gql} from "@apollo/client";
import {StorageFragment} from "./Storage";

export const BannerFragment = gql`
	fragment BannerFragment on Banner {
		id
		code
		position
		mobile
		active
		popup
		blank
		alt
		link
	}
`

export const BannerFragmentAdmin = gql`
	fragment BannerFragmentAdmin on Banner {
		id
		code
		position
		mobile
		active
		popup
		blank
		alt
		alts
		link
		links
	}
`

export const GetBanners = gql`
	${BannerFragmentAdmin}
	${StorageFragment}
	query ($code: String!, ) {
		GetBanners (code: $code, ) {
			...BannerFragmentAdmin
			image			{
				...StorageFragment
			}
			mobile_image			{
				...StorageFragment
			}
		}
	}
`

export const GetBannersAdmin = gql`
	${BannerFragmentAdmin}
	${StorageFragment}
	query ($code: String, $not_code: Boolean, ) {
		GetBannersAdmin (code: $code, not_code: $not_code, ) {
			...BannerFragmentAdmin
			images			{
				...StorageFragment
			}
			mobile_images			{
				...StorageFragment
			}
		}
	}
`

export const CreateBanner = gql`
	${BannerFragmentAdmin}
	mutation ($code: String, $active: Boolean, ) {
		CreateBanner (code: $code, active: $active, ) {
			...BannerFragmentAdmin
		}
	}
`

export const UpdateBanner = gql`
	${BannerFragmentAdmin}
	mutation ($id: ID!, $image_id: String, $mobile_image_id: String, $active: Boolean, $mobile: Boolean, $popup: Boolean, $blank: Boolean, $alt: String, $link: String, ) {
		UpdateBanner (id: $id, image_id: $image_id, mobile_image_id: $mobile_image_id, active: $active, mobile: $mobile, popup: $popup, blank: $blank, alt: $alt, link: $link, ) {
			...BannerFragmentAdmin
		}
	}
`

export const DeleteBanner = gql`
	${BannerFragmentAdmin}
	mutation ($id: ID!, ) {
		DeleteBanner (id: $id, ) {
			...BannerFragmentAdmin
		}
	}
`

