import React from "react";
import {useState, useEffect} from "react";
import {useNavigation} from "react-router-dom";


function RouterProgressBar()   {
    const navigation = useNavigation();
    const [show, setShow] = useState(false);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        if(navigation.formAction)  {
            return;
        }
        if(navigation.state === "loading")  {
            setWidth(90);
            setShow(true);
        } else  {
            setWidth(100);
            setTimeout(() => {
                setWidth(0);
                setShow(false);
            }, 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigation.state]);

    return  <div className={`${show ? "" : "invisible"} z-50 pointer-events-none h-1 bg-gray-200 dark:bg-gray-600 fixed left-0 top-0 right-0`}>
        <div className="h-full transition-[width] rounded-r-md duration-500 router_progress_bar" style={{width: `${width}%`}} />
    </div>
}
export default RouterProgressBar;