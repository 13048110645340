import {Suspense, useEffect} from "react";
import {useGetPages} from "../../_graphql/GetPages";
import {useGetPagesAdmin} from "../../_graphql/GetPagesAdmin";
import context from "../../_app/context";
import {pages} from "../../compiled";
import {makeVar} from "@apollo/client";
import PageErrorBoundary from "../widgets/PageErrorBoundary";

//@@@ type=register

const pagesVar = makeVar({});
const currentPageVar = makeVar(null);

function LoadPagesRegister()   {
    context.debugger("LoadPagesRegister");
    context.pages = pagesVar;
    context.currentPage = currentPageVar;

    const loader = context.admin() ? useGetPagesAdmin : useGetPages;
    const {data, error} = loader();

    const pagesByName = {};
    const slug = (item, acc) => {
        if(item.slug === null || item.slug === undefined) return null;
        if(item.layout && pagesByName[item.layout]) {
            acc = slug(pagesByName[item.layout], acc);
        }
        if(item.slug.includes("{")) {
            const sl = item.slug.replaceAll("{", ":").replaceAll("}", "");
            return acc + "/" + sl;
        }
        return acc + (item.slug ? "/"+item.slug : "");
    }

    useEffect(() => {
        if(!data || data.length === 0)  return;

        for(let i=0; i<data.length; i++) {
            const item = {...data[i]};
            const sl = item.slug.replaceAll("{", ":").replaceAll("}", "");
            item.path = item.layout ? sl : "/" + sl;
            if (item.slug === "" && item.layout) {
                item.index = true;
            }
            item.pageName = item.page || "page." + item.id;

            let compiledPage = pages["design.BlocksTemplate"];
            if (item.page) {
                if (!pages[item.page]) {
                    console.log("NE postoji stranica za:", item.page);
                    continue;
                }
                compiledPage = pages[item.page];
            }
            const Element = compiledPage.lazy;
            item.element = <Suspense fallback={
                <div className={compiledPage.fallback || ""}/>
            }>
                <Element />
            </Suspense>

            item.errorElement = <PageErrorBoundary />

            if (compiledPage.loader || item.layout) {
                //if(!item.page?.endsWith("Layout"))   {
                    item.loader = async (params) => {
                        context.debugger(`START LOADER ${item.pageName}`);
                        if(!item.children)  {
                            // TODO menja se pre nego sto se promeni strana (problem sa category seo)
                            context.currentPage(item);
                        }
                        Element.preload();

                        if (compiledPage.loader) {
                            const loader = compiledPage.loader;
                            return loader(params);
                        }
                    }
                //}
            }
            if (compiledPage.action) {
                const action = compiledPage.action;
                item.action = async (params) => {
                    context.debugger(`START ACTION ${item.pageName}`)
                    return action(params);
                }
            }

            if(item.path.includes("?")) {
                item.path = item.path.replaceAll("?", "");
                const pathArr = item.path.split("/");
                let base = "";
                let params = [];
                for(let i=0; i<pathArr.length; i++) {
                    if(pathArr[i] === "") continue;
                    if(pathArr[i].includes(":"))   {
                        params.push(pathArr[i])
                    } else  {
                        base = base + pathArr[i];
                    }
                }
                let paramBase = "";
                for(let i=0; i<params.reverse().length; i++) {
                    paramBase = "/" + params[i] + paramBase;
                    const np = base + paramBase;
                    pagesByName["generate:" + np] = {...item, id: np,  path: np, menu: null};
                }
                item.path = base;
            }
            pagesByName[item.pageName] = item;
        }

        for(let pageName in pagesByName) {    // NESTING pages
            const item = pagesByName[pageName];
            if(item.parent_id)  {
                for(let parentName in pagesByName) {
                    if(pagesByName[parentName].id === item.parent_id) {
                        if(!pagesByName[parentName].nested) pagesByName[parentName].nested = [];
                        pagesByName[parentName].nested.push(item);
                        break;
                    }
                }
            }
            if(item.layout) {
                if(!pagesByName[item.layout]) {
                    console.log("NE postoji layout za:", item.layout);
                    continue;
                }
                if(!pagesByName[item.layout].children)   pagesByName[item.layout].children = [];
                pagesByName[item.layout].children.push(item);
            }
            pagesByName[pageName].full_path = slug(item, "");
        }

        context.pages(pagesByName);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if(error)   return error;

    return null
}
export default LoadPagesRegister;