import slugify from "slugify";
import config from "../../config";

function validateEmailAddress(emailString) {
    if(emailString === "")  {
        return true;
    }
    // check for @ sign
    const atSymbol = emailString.indexOf("@");
    if(atSymbol < 1) return false;

    const atSymbolArr = emailString.split("@");

    var dot = atSymbolArr[1].indexOf(".");
    if(dot < 1) return false;

    // check that the dot is not at the end
    if (dot === atSymbolArr[1].length - 1) return false;

    return true;
}

function validator({form, name, value, rules, trans})  {
    const formErrors = {};
    const formValues = {};

    for(let formKey in form)   {
        const errors = [];
        let formValue = form[formKey];
        if(formKey === name)    {
            formValue = value;
        }
        /*if(formValue === null)  {
            formValue = "";
        }*/
        formValues[formKey] = formValue;

        for(let i=0; i<rules[formKey]?.length; i++) {
            const rule = rules[formKey][i];

            if(rule.startsWith("slugify")) {
                const arr = rule.split(":");
                if(arr[1] && formKey !== name)  {
                    const slug = arr[1] === name ? value : form[arr[1]];
                    formValues[formKey] = slugify(slug, {trim: false, lower: true});
                } else  {
                    formValues[formKey] = slugify(formValue, {trim: false, lower: true});
                }
            }
            if(formValue === "" && formKey !== name)    continue;

            if(rule === "nullable" && !formValue)   {
                formValues[formKey] = null;
            }
            else if(rule === "integer" && formValue) {
                formValues[formKey] = parseInt(formValue);
            }
            else if(rule === "numeric" && formValue) {
                formValue = formValue.trim().replace(",", ".");
                if(!formValue.endsWith(".")) {
                    formValue = parseFloat(formValue);
                }
                formValues[formKey] = formValue;
            }
            else if(rule === "required") {
                if(!formValue)  {
                    errors.push(trans("polje je obavezno"));
                }
            }
            else if(rule === "link") {
                if(formValue.startsWith(config.modules._app.appUrl))    {
                    formValues[formKey] = formValue.replace(config.modules._app.appUrl, "");
                }
            }
            else if(rule === "email") {
                if (!validateEmailAddress(formValue))    {
                    errors.push(trans("polje nije validan email"));
                }
            }
            else if(rule.startsWith("min:")) {
                const min = parseInt(rule.split(":")[1]);
                if(formValue.length < min) errors.push(trans("polje mora biti min") + min + trans("karaktera"));
            }
            else if(rule.startsWith("max:")) {
                const max = parseInt(rule.split(":")[1]);
                if(formValue.length > max) errors.push(trans("polje mora biti max") + max + trans("karaktera"));
            }
        }
        if(errors.length > 0)   {
            formErrors[formKey] = errors;
        }
    }
    return {errors: formErrors, values: formValues}
}
export default validator;