import context from "../../_app/context";
import {useReactiveVar} from "@apollo/client";

function RouterSeoPageChange() {
    const {settings, setting} = context;
    const currentPage = useReactiveVar(context.currentPage);
    useReactiveVar(settings);

    const tag = document.querySelector("title");
    tag.innerHTML = currentPage?.name + " ";

    const defaultSeoTitle = setting("default SEO title");
    if(defaultSeoTitle) tag.innerHTML += defaultSeoTitle;
    if(currentPage?.seo_title)  tag.innerHTML = currentPage.seo_title;

    return null;
}
export default RouterSeoPageChange;