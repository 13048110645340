import {gql} from "@apollo/client";

export const StorageFolderFragment = gql`
	fragment StorageFolderFragment on StorageFolder {
		id
		name
	}
`

export const StorageFolderWithCountFragment = gql`
	fragment StorageFolderWithCountFragment on StorageFolder {
		id
		name
		storage_count
	}
`

export const GetStorageFolders = gql`
	${StorageFolderWithCountFragment}
	query  {
		GetStorageFolders  {
			...StorageFolderWithCountFragment
		}
	}
`

export const CreateStorageFolder = gql`
	${StorageFolderFragment}
	mutation ($name: String!, ) {
		CreateStorageFolder (name: $name, ) {
			...StorageFolderFragment
		}
	}
`

export const DeleteStorageFolder = gql`
	${StorageFolderFragment}
	mutation ($id: ID!, ) {
		DeleteStorageFolder (id: $id, ) {
			...StorageFolderFragment
		}
	}
`

