import {gql} from "@apollo/client";

export const CreateAdminResetTokenFragment = gql`
	fragment CreateAdminResetTokenFragment on AdminReset {
		error
		success
	}
`

export const AdminResetPasswordFragment = gql`
	fragment AdminResetPasswordFragment on AdminReset {
		error
		success
	}
`

export const AdminResetTokenFragment = gql`
	fragment AdminResetTokenFragment on AdminReset {
		id
		email
		token
	}
`

export const CheckAdminResetToken = gql`
	${AdminResetTokenFragment}
	query ($token: String!, ) {
		CheckAdminResetToken (token: $token, ) {
			...AdminResetTokenFragment
		}
	}
`

export const CreateAdminResetToken = gql`
	${CreateAdminResetTokenFragment}
	mutation ($email: String!, ) {
		CreateAdminResetToken (email: $email, ) {
			...CreateAdminResetTokenFragment
		}
	}
`

export const ResetAdminPassword = gql`
	${AdminResetPasswordFragment}
	mutation ($email: String!, $password: String!, $token: String!, ) {
		ResetAdminPassword (email: $email, password: $password, token: $token, ) {
			...AdminResetPasswordFragment
		}
	}
`

